import * as pathToRegexp from 'path-to-regexp'

import { getCurrency } from '../../app/crypto/currencyUtils'
import { cleanTicker } from '../util'

/**
 * Compile route path by applying parameters to it
 *
 * @example
 * // Outputs /route/1?query=foo
 * compilePath('/route/:id?query=:query', { id: 1, query: 'foo' })
 */
function compilePath(
  path: string,
  params?: pathToRegexp.ParamData,
  options?: pathToRegexp.ParseOptions & pathToRegexp.CompileOptions
) {
  return pathToRegexp.compile(path, options)(params)
}

export const path = {
  home: '/',
  news: '/news.ashx',
  screener: '/screener.ashx',
  charts: '/charts',
  groups: '/groups.ashx',
  portfolio: '/portfolio.ashx',
  insider: '/insidertrading.ashx',
  elite: '/elite.ashx',
  search: '/search.ashx\\?p=:ticker',
  quote: '/quote.ashx\\?t=:ticker',
  contact: '/contact.ashx',

  alerts: '/alerts.ashx',
  help: '/help/screener.ashx',
  account: '/myaccount.ashx',
  login: '/login.ashx',
  logout: '/logout.ashx',

  // Maps
  maps: '/map.ashx',
  bubbles: '/bubbles.ashx',

  // Futures
  futures: '/futures.ashx',
  futuresPerf: '/futures_performance.ashx',
  futuresCharts: '/futures_charts.ashx',
  futuresChartDetail: '/futures_charts.ashx\\?t=:ticker',

  // Forex
  forex: '/forex.ashx',
  forexPerf: '/forex_performance.ashx',
  forexCharts: '/forex_charts.ashx',
  forexChartDetail: '/forex_charts.ashx\\?t=:ticker',

  // Crypto
  crypto: '/crypto.ashx',
  cryptoPerf: '/crypto_performance.ashx',
  cryptoCharts: '/crypto_charts.ashx',
  cryptoChartDetail: '/crypto_charts.ashx\\?t=:ticker&c=:currency',

  // Backtsest
  backtests: '/backtests.ashx',
  backtestCreate: '/backtest_create.ashx',
  backtestDetail: '/backtest_detail.ashx\\?id=:backtestId',
  backtestPresets: 'backtests.ashx\\?presets=1',
}

export const link = {
  toHome: () => path.home,
  toNews: () => path.news,
  toScreener: () => path.screener,
  toCharts: () => path.charts,
  toGroups: () => path.groups,
  toPortfolio: () => path.portfolio,
  toInsider: () => path.insider,
  toElite: () => path.elite,
  toSearch: (ticker: string) => compilePath(path.search, { ticker }),
  toQuote: (ticker: string) => compilePath(path.quote, { ticker }),
  toContact: () => path.contact,

  toAlerts: () => path.alerts,
  toHelp: () => path.help,
  toAccount: () => path.account,
  toLogin: () => path.login,
  toLogout: () => path.logout,

  toMaps: () => path.maps,
  toBubbles: () => path.bubbles,

  toFutures: () => path.futures,
  toFuturesPerf: () => path.futuresPerf,
  toFuturesCharts: () => path.futuresCharts,
  toFuturesChartDetail: (ticker: string) => compilePath(path.futuresChartDetail, { ticker: cleanTicker(ticker) }),

  toForex: () => path.forex,
  toForexPerf: () => path.forexPerf,
  toForexCharts: () => path.forexCharts,
  toForexChartDetail: (ticker: string) => compilePath(path.forexChartDetail, { ticker: cleanTicker(ticker) }),

  toCrypto: () => path.crypto,
  toCryptoPerf: () => path.cryptoPerf,
  toCryptoCharts: () => path.cryptoCharts,
  toCryptoChartDetail: (ticker: string) =>
    compilePath(path.cryptoChartDetail, { ticker: cleanTicker(ticker), currency: getCurrency(cleanTicker(ticker)) }),

  toBacktests: () => path.backtests,
  toBacktestCreate: () => path.backtestCreate,
  toBacktestDetail: (backtestId: string | number) =>
    compilePath(path.backtestDetail, { backtestId: String(backtestId) }),
  toBacktestPresets: () => path.backtestPresets,
}
