import * as Ariakit from '@ariakit/react'
import { useQuery } from '@tanstack/react-query'
import classnames from 'classnames'
import * as React from 'react'

import { getSanitizedTicker } from '../../../app/shared/ticker-sanitizer'
import { searchApiRequest } from '../../services/api'
import { link } from '../../services/routing'
import { Button } from '../button'
import { IconNameType } from '../icon'
import { InputSize, InputTheme } from '../input'
import { Search, SearchProps, useSearchComboboxState } from '../search'
import { TickerSearchItem } from './TickerSearchItem'
import { getRedirectForTicker } from './util'

interface Props extends Omit<SearchProps, 'state'> {
  /**
   * Whether the input should automatically receive focus when mounted
   */
  autoFocus?: boolean

  /**
   * Initial value for the input field
   */
  defaultValue?: string

  /**
   * Default placement position of the combobox popover
   * Inherits placement options from Ariakit ComboboxStore
   */
  defaultPlacement?: Ariakit.ComboboxStoreProps['placement']

  /**
   * Placeholder text shown when the input is empty
   */
  placeholder?: string

  /**
   * Visual theme variant for the input
   * References predefined styles from InputTheme
   */
  theme?: keyof typeof InputTheme

  /**
   * Size variant for the input
   * References predefined sizes from InputSize
   */
  size?: keyof typeof InputSize

  /**
   * Additional CSS class name for the input element
   */
  inputClass?: string

  /**
   * ChartIndex used for data-testid
   */
  chartIndex?: number

  /**
   * Whether to show the "Show All" option
   *
   * @default true
   */
  isShowAllVisible?: boolean

  /**
   * Whether search items should be rendered as links
   *
   * @default true
   */
  isSearchItemLink?: boolean

  /**
   * Content to show on the right side of the input (icon or custom element)
   */
  rightContent?: JSX.Element | IconNameType

  /**
   * Callback function triggered when a ticker is selected
   *
   * @param ticker - The selected ticker string
   */
  onTickerSelected?: (ticker: string) => void
}

export function TickerSearch({ isShowAllVisible = true, isSearchItemLink = true, chartIndex, ...props }: Props) {
  const searchComboboxState = useSearchComboboxState({
    defaultValue: props.defaultValue,
    placement: props.defaultPlacement ?? 'bottom-start',
    setSelectedValue: (value) => {
      if (isSearchItemLink) {
        window.location.href = getRedirectForTicker(value as string)
      } else {
        props.onTickerSelected?.(getSanitizedTicker(value as string))
        // We have to reset the value (clear the input) in next tick otherwise it doesn't work
        setTimeout(() => searchComboboxState.setValue(''))
      }
    },
  })

  const value = searchComboboxState.useState('value')
  const activeValue = searchComboboxState.useState('activeValue')
  const { data, error, isLoading, refetch } = useQuery(['search', value], () => searchApiRequest(value), {
    enabled: value.length > 0,
    keepPreviousData: true,
    cacheTime: Infinity,
    staleTime: Infinity,
  })

  return (
    <Search
      state={searchComboboxState}
      aria-label="Search"
      inputProps={{
        theme: props.theme,
        maxLength: 100,
        hasFocusStyle: !activeValue,
        size: props.size,
        placeholder: props.placeholder ?? 'Search symbol',
        inputClass: classnames(props.inputClass, {
          'focus:border-blue-500 dark:focus:border-blue-400': !!value && !activeValue,
        }),
        rightContent: props.rightContent,
      }}
      popoverProps={{
        className: classnames('h-64 w-full sm:h-auto', {
          'sm:w-110': !props.isMatchInputAndBoxWidth,
        }),
      }}
      inputTestId={chartIndex !== undefined ? `chart-${chartIndex}-settings-search-input` : undefined}
      footer={
        isShowAllVisible ? (
          <Button
            as="a"
            className="w-full shrink-0 self-center"
            contentClass="text-center"
            href={link.toSearch(activeValue ?? value)}
          >
            Show All Results
          </Button>
        ) : undefined
      }
      isLoading={isLoading}
      isError={!!error}
      onErrorRetry={refetch}
      {...props}
    >
      <div className="search-list">
        {data?.map((item) => (
          <TickerSearchItem
            key={item.ticker}
            state={searchComboboxState}
            item={item}
            dataTestId={chartIndex !== undefined ? `chart-${chartIndex}-settings-suggestion-${item.ticker}` : undefined}
            {...(isSearchItemLink
              ? {
                  as: 'a',
                  href: getRedirectForTicker(item.ticker),
                }
              : {})}
          />
        ))}
      </div>
    </Search>
  )
}
