import throttle from 'lodash.throttle'
import * as React from 'react'

interface Props {
  throttleWaitInMs?: number
  isWatching?: boolean
}

export function useWindowSize({ throttleWaitInMs = 300, isWatching = true }: Props = {}) {
  const [size, setSize] = React.useState({
    clientWidth: document.documentElement.clientWidth,
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight,
  })

  React.useEffect(() => {
    if (!isWatching) return

    const handleResize = throttle(() => {
      setSize({
        clientWidth: document.documentElement.clientWidth,
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      })
    }, throttleWaitInMs)

    window.addEventListener('resize', handleResize)

    return () => {
      handleResize.cancel()
      window.removeEventListener('resize', handleResize)
    }
  }, [throttleWaitInMs, isWatching])

  return size
}
