import classNames from 'classnames'
import React from 'react'

import { SearchApiResult } from '../../services/api'
import { ComboBoxItem, ComboBoxItemProps } from '../combo-box'
import { Highlight } from '../highlight'
import { PropsWithAs } from '../types'

interface SearchItemProps extends Pick<ComboBoxItemProps, 'state'>, Partial<PropsWithAs<React.ElementType>> {
  item: SearchApiResult
  dataTestId?: string
}

export function TickerSearchItem({ item, dataTestId, ...props }: SearchItemProps) {
  const value = props.state!.useState('value')
  const isTickerWide = item.ticker.length > 7

  return (
    <ComboBoxItem
      {...props}
      value={item.ticker}
      className="search-item h-auto min-h-7 w-full shrink-0 appearance-none py-1 leading-snug"
      contentClass="overflow-hidden px-1 -mx-1 search-item"
      data-testid={dataTestId}
    >
      <span
        className={classNames({
          'w-16': !isTickerWide,
          'w-20': isTickerWide,
        })}
      >
        <Highlight text={item.ticker} highlight={value} />
      </span>
      <span className="-mx-1 flex-1 whitespace-normal px-1 text-gray-600 dark:text-gray-200">
        <Highlight text={item.company} highlight={value} />
      </span>
      <span className="ml-2 uppercase text-gray-400">{item.exchange}</span>
    </ComboBoxItem>
  )
}
