import * as React from 'react'
import { useInRouterContext } from 'react-router-dom'

import { ComboBox, ComboBoxProps } from '../combo-box'
import { IconNameType } from '../icon'
import { SearchListItem } from './constants'
import { SearchError } from './search-error'
import { SearchList } from './search-list'
import {
  SearchNavigationWrapperInRouterContext,
  SearchNavigationWrapperOutsideRouterContext,
} from './search-navigation-wrapper'

export interface SearchProps
  extends Pick<
    ComboBoxProps,
    'state' | 'autoFocus' | 'inputProps' | 'popoverProps' | 'aria-label' | 'inputTestId' | 'getInputValue'
  > {
  /**
   * Array of items to display in the search list
   */
  items?: SearchListItem[]

  /**
   * Minimum length of input value before showing results
   *
   * @default 1
   */
  minValueLength?: number

  /**
   * Placeholder text for the search input
   */
  placeholder?: string

  /**
   * Content to show on the right side of the input (icon or custom element)
   */
  rightContent?: JSX.Element | IconNameType

  /**
   * Custom footer content shown at the bottom of the search results
   */
  footer?: React.ReactNode

  /**
   * Whether to show the search icon in the input
   *
   * @default true
   */
  hasSearchIcon?: boolean

  /**
   * Whether to match the width of the input and its dropdown box
   */
  isMatchInputAndBoxWidth?: boolean

  /**
   * Whether the search is currently loading results
   *
   * @default false
   */
  isLoading?: boolean

  /**
   * Whether there was an error loading the search results
   *
   * @default false
   */
  isError?: boolean

  /**
   * Callback function to retry when there's an error
   */
  onErrorRetry?: () => void
}

export function Search({
  state,
  children,
  items,
  footer,
  'aria-label': ariaLabel,
  inputProps,
  popoverProps,
  inputTestId,
  minValueLength = 1,
  hasSearchIcon = true,
  isLoading = false,
  isError = false,
  onErrorRetry,
  ...props
}: React.PropsWithChildren<SearchProps>) {
  const isInRouterContext = useInRouterContext()
  const isMounted = state.useState('mounted')

  const Wrapper = isInRouterContext
    ? SearchNavigationWrapperInRouterContext
    : SearchNavigationWrapperOutsideRouterContext

  return (
    <Wrapper state={state} items={items}>
      <ComboBox
        state={state}
        minValueLength={minValueLength}
        aria-label={ariaLabel}
        autoFocus={props.autoFocus}
        inputProps={{
          autoComplete: 'off',
          autoCorrect: 'off',
          autoCapitalize: 'off',
          onBlur: (e) => {
            // prevent combobox from setting input value on input blur
            e.preventDefault()
          },
          leftContent: hasSearchIcon ? 'search' : undefined,
          ...inputProps,
        }}
        popoverProps={{
          sameWidth: props.isMatchInputAndBoxWidth,
          fitViewport: !props.isMatchInputAndBoxWidth,
          ...popoverProps,
        }}
        inputTestId={inputTestId}
        getInputValue={props.getInputValue}
      >
        {isMounted &&
          (isError ? (
            <SearchError onRetry={onErrorRetry} />
          ) : (
            <SearchList state={state} footer={footer} items={items} isLoading={isLoading}>
              {children}
            </SearchList>
          ))}
      </ComboBox>
    </Wrapper>
  )
}
