import classnames from 'classnames'
import React, { ComponentProps, ElementType, HTMLProps, PropsWithChildren, ReactNode } from 'react'

import { PropsWithAs } from './types'

type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6

interface HeadingProps {
  /**
   * Heading level
   */
  level: HeadingLevel
}

function getHeadingElement(level: HeadingLevel) {
  switch (level) {
    case 1:
      return 'h1'
    case 2:
      return 'h2'
    case 3:
      return 'h3'
    case 4:
      return 'h4'
    case 5:
      return 'h5'
    default:
      return 'h6'
  }
}

export function Heading<TagType extends React.ElementType = 'h1'>({
  as: asComponent,
  level,
  children,
  ...props
}: PropsWithAs<TagType> & PropsWithChildren<HeadingProps>) {
  return React.createElement(
    asComponent ?? getHeadingElement(level),
    {
      ...props,
      className: classnames(props.className, 'font-bold', {
        'text-3xl': level === 1,
        'text-2xl': level === 2,
        'text-xl': level === 3,
        // We don't want to set any specific fontsize because it's used e.g. in DialogHeader
        // where it uses font-size: inherit; and is different on old/redesign versions
        'leading-[1.375]': level === 4,
        'text-sm': level === 5,
        'text-2xs': level === 6,
      }),
    },
    children
  )
}

interface ParagraphProps<AsTag extends ElementType> extends Omit<HTMLProps<ComponentProps<AsTag>>, 'size' | 'as'> {
  /**
   * Determines which element the text should render to
   *
   * @default p
   */
  as?: ElementType

  /**
   * Font size for the paragraph
   * - tiny - 10px
   * - small - 12px
   * - default - 14px
   * - medium - 16px
   * - large - 18px
   *
   * @default default
   */
  size?: 'tiny' | 'small' | 'default' | 'medium' | 'large'

  /**
   * Paragraph font weight
   */
  medium?: boolean
}

export function Paragraph<AsTag extends ElementType>({
  as = 'p',
  size = 'default',
  medium,
  children,
  ...props
}: PropsWithChildren<ParagraphProps<AsTag>>) {
  return React.createElement(
    as,
    {
      ...props,
      className: classnames(props.className, {
        'font-medium': medium,
        'text-lg': size === 'large',
        'text-sm': size === 'default',
        'text-2xs': size === 'small',
        'text-3xs': size === 'tiny',
      }),
    },
    children
  )
}

interface LabelProps extends Omit<HTMLProps<HTMLLabelElement>, 'title'> {
  title?: ReactNode
  titleClassName?: string
  isHorizontal?: boolean
}

export function Label({ title, titleClassName, isHorizontal, children, ...props }: PropsWithChildren<LabelProps>) {
  return (
    <label {...props} className={classnames(props.className, 'flex grow', { 'flex-col': !isHorizontal })}>
      {title && <span className={classnames('text-2xs', { 'mb-1': !isHorizontal }, titleClassName)}>{title}</span>}
      {children}
    </label>
  )
}
