import { RootChartConfigObject } from '../../types/shared'
import { SpecificChartFunctionality, TIMEFRAME } from '../constants/common'
import { getIntradayChartConfig } from '../controllers/renderUtils'
import { isForexFuturesCryptoPage } from './chart'

export function getChartLayoutSizeConfig(
  layoutConfig: Pick<
    RootChartConfigObject,
    'idea' | 'barWidth' | 'barMargin' | 'charts' | 'specificChartFunctionality' | 'width'
  >,
  p = TIMEFRAME.d
) {
  const { clientWidth } = document.body
  const { premarket, aftermarket, dateRange } = layoutConfig.charts[0]
  const maxChartWidth = clientWidth - 40 // 40px left right chart padding = (19px * 2 for margin left/right) + (1px * 2 for border)
  let width = 990
  let barWidth = 3
  let barMargin = 1
  let timeframeMinutes = 0
  const isForexFuturesCrypto = isForexFuturesCryptoPage(layoutConfig.specificChartFunctionality!)
  const isChartPage = layoutConfig.specificChartFunctionality === SpecificChartFunctionality.chartPage

  if (isChartPage) {
    return {
      width: layoutConfig.width,
      barWidth,
      barMargin,
    }
  }

  switch (p) {
    case TIMEFRAME.i1:
      timeframeMinutes = 1
      barWidth = 1
      if (premarket || aftermarket || clientWidth < 1650) {
        barMargin = 0
      }
      break
    case TIMEFRAME.i2:
      timeframeMinutes = 2
      break
    case TIMEFRAME.i3:
      timeframeMinutes = 3
      if (premarket && aftermarket && clientWidth <= 1505) {
        barWidth = 2
      }
      break
    case TIMEFRAME.i5:
      timeframeMinutes = 5
      barWidth = 5
      barMargin = clientWidth >= 1100 ? 2 : 1
      break
    case TIMEFRAME.i10:
      width = 1058 // 39bars per day * 5days * 5px per bar + 35px left offset + 48px right offset
      barMargin = 0
      break
    case TIMEFRAME.i15:
    case TIMEFRAME.i30:
      width = 1019
      break
    case TIMEFRAME.h:
      width = 1008
      break
    case TIMEFRAME.h2:
    case TIMEFRAME.h4:
      width = 1020
      break
    case TIMEFRAME.d:
      width = 1282
      break
    case TIMEFRAME.w:
    case TIMEFRAME.m:
      width = 994
      break
  }

  switch (p) {
    case TIMEFRAME.i1:
    case TIMEFRAME.i2:
    case TIMEFRAME.i3:
    case TIMEFRAME.i5:
      const commonProps = {
        timeframeMinutes,
        barWidthWithMargin: barWidth + 2 + barMargin,
        hasAftermarket: aftermarket,
        hasPremarket: premarket,
      }
      width = getIntradayChartConfig({
        ...commonProps,
        maxWidth: maxChartWidth,
        canCropChart: !dateRange,
      }).chartWidthWithOffset
      break
  }

  // If FFC chart use DAILY type width
  if (isForexFuturesCrypto) width = 1282

  // If chart is wider than available space in vieport override width
  if (maxChartWidth <= width) {
    width = Math.max(994, maxChartWidth)
    if (p === TIMEFRAME.d) {
      barWidth = 2
      barMargin = 0
      width = 991
    }
  }

  // If idea chart or FFC chart use bar configs from layout
  if (layoutConfig.idea || isForexFuturesCrypto) {
    barWidth = layoutConfig.barWidth!
    barMargin = layoutConfig.barMargin!
  }

  return {
    width,
    barWidth,
    barMargin,
  }
}
