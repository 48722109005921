import * as d3 from 'd3'

import * as constants from './constants/generator'
import { ISettings } from './constants/settings'
import { MapDataIndustry, MapDataNode, MapDataRoot, MapDataRow, MapDataSector, MapTypeId, NodeDepth } from './types'

export default class LayoutGenerator {
  width: number
  height: number
  settings: ISettings
  layout?: Array<d3.HierarchyRectangularNode<MapDataNode>>
  sectors: MapDataSector[] = []
  industries: MapDataIndustry[] = []
  nodes: MapDataNode[] = []
  isSmall: boolean
  type: MapTypeId
  minSectorHeight: number

  static smallIndustryPadding = {
    top: 4,
    right: 1,
    bottom: 1,
    left: 1,
  }

  static smallIndustryGeoPadding = {
    top: 4,
    right: 1,
    bottom: 1,
    left: 1,
  }

  static getSizeRatio = (type: MapTypeId, isSmall: boolean) => {
    if (isSmall) {
      if (type === MapTypeId.World) {
        return constants.SMALL_MAP_GEO_HEIGHT_RATIO
      }
      return constants.SMALL_MAP_HEIGHT_RATIO
    }

    return constants.LARGE_MAP_HEIGHT_RATIO
  }

  static calculateHeight = (
    width: number,
    type: MapTypeId,
    isSmall: boolean,
    ratio = LayoutGenerator.getSizeRatio(type, isSmall)
  ) => ~~(width / ratio)

  static calculateWidth = (height: number, type: MapTypeId, isSmall: boolean) => {
    const ratio = LayoutGenerator.getSizeRatio(type, isSmall)

    return Math.round(height * ratio)
  }

  static isNodeHeaderVisible(node: any, settings: ISettings) {
    const deltaX = node.dx ?? Math.round(node.x1) - Math.round(node.x0)
    const deltaY = node.dy ?? Math.round(node.y1) - Math.round(node.y0)

    const isLargeNode = deltaX >= constants.LARGE_NODE_SIZE.dx && deltaY >= constants.LARGE_NODE_SIZE.dy
    const showHeader =
      deltaY - settings.industry.padding.top - settings.industry.header.height > constants.LARGE_NODE_MIN_HEIGHT

    return isLargeNode && showHeader
  }

  constructor(width: number, height: number, type: MapTypeId, settings: ISettings, isSmall: boolean = false) {
    this.width = width
    this.height = height
    this.settings = settings
    this.isSmall = isSmall
    this.type = type
    this.minSectorHeight = settings.sector.padding.top + settings.sector.header.height + constants.SECTOR_MIN_HEIGHT
  }

  getExportData() {
    let mapRoot: MapDataRoot
    if (this.type === MapTypeId.World) {
      mapRoot = this._rootsToRoot(this.industries)
    } else {
      mapRoot = this.layout?.find((node) => node.data.name === 'Root') as unknown as MapDataRoot
    }

    // Save some file size by filtering out small nodes on maps where hover is not a concern
    if (this.isSmall) {
      mapRoot.children = this.filterVisibleNodes(mapRoot.children)
    }

    const stringified = JSON.stringify(mapRoot, (key, value) => (key === 'parent' ? '[Circular]' : value))

    return JSON.parse(stringified)
  }

  getLayoutData() {
    return {
      nodes: this.nodes,
      sectors: this.sectors,
      industries: this.industries,
    }
  }

  filterVisibleNodes = <T extends MapDataRow & { children?: MapDataRow[] }>(arr: T[]) =>
    arr.filter((node) => {
      const isVisible = this.isNodeVisible(node)
      if (isVisible && node.children?.length) {
        node.children = this.filterVisibleNodes(node.children)
      }
      return isVisible
    })

  isNodeVisible(d: MapDataRow) {
    if (!d.depth || d.depth < NodeDepth.Industry) return true

    return d.dx > 0 && d.dy > 0
  }

  getLayout(data: MapDataRoot) {
    this.nodes = []
    this.sectors = []
    this.industries = []

    switch (this.type) {
      case MapTypeId.Sector:
        this.layout = this._generateSP500(data).descendants()
        break
      case MapTypeId.SectorFull:
        this.layout = this._generateFull(data).descendants()
        break
      case MapTypeId.Portfolio:
      case MapTypeId.Screener:
      case MapTypeId.ETFHoldings:
        this.layout = this._generateScreener(data).descendants()
        break
      case MapTypeId.ManagersAndFunds:
        this.layout = this._generateSectorSizeOrdered(data).descendants()
        break
      case MapTypeId.World:
        this.layout = this._generateWorld(data)
        break
      case MapTypeId.ETF:
        this.layout = this._generateETF(data).descendants()
        break
    }

    this.layout?.forEach((d: any) => {
      this._transformNode(d)

      if (!this.isNodeVisible(d)) return

      if (!d.children) {
        this.nodes.push(d)
      } else if (d.parent && !d.parent.parent) {
        this.sectors.push(d)
      } else if (d.parent && !!d.parent.parent && d.children) {
        this.industries.push(d)
      }
    })

    return this.getLayoutData()
  }

  _transformNode(d: d3.HierarchyRectangularNode<MapDataNode>) {
    Object.assign(d, {
      name: d.data.name,
      description: d.data.description,
      perf: d.data.perf,
      additional: d.data.additional,
      x: d.x0,
      y: d.y0,
      dx: d.x1 - d.x0,
      dy: d.y1 - d.y0,
    })
  }

  _rootsToRoot(roots: any) {
    return {
      name: 'Root',
      children: [
        {
          name: 'World',
          children: roots,
        },
      ],
      duplicateTickers: 0,
    } as MapDataRoot
  }

  _getPadding = (d: d3.HierarchyRectangularNode<MapDataNode>) => {
    // Root
    if (d.depth === NodeDepth.Root) {
      return [
        this.settings.padding.top,
        this.settings.padding.right,
        this.settings.padding.bottom,
        this.settings.padding.left,
      ]
    }

    // Industries
    if (d.depth === NodeDepth.Industry) {
      if (this.isSmall || LayoutGenerator.isNodeHeaderVisible(d, this.settings)) {
        return [
          this.settings.industry.padding.top + this.settings.industry.header.height,
          this.settings.industry.padding.right,
          this.settings.industry.padding.bottom,
          this.settings.industry.padding.left,
        ]
      }

      return [
        LayoutGenerator.smallIndustryPadding.top,
        LayoutGenerator.smallIndustryPadding.right,
        LayoutGenerator.smallIndustryPadding.bottom,
        LayoutGenerator.smallIndustryPadding.left,
      ]
    }

    // Sectors
    return [
      this.settings.sector.padding.top + this.settings.sector.header.height,
      this.settings.sector.padding.right,
      this.settings.sector.padding.bottom,
      this.settings.sector.padding.left,
    ]
  }

  _getPaddingGeo = (d: d3.HierarchyRectangularNode<MapDataNode>) => {
    // Root
    if (d.depth === NodeDepth.Root) {
      return [
        this.settings.padding.top,
        this.settings.padding.right,
        this.settings.padding.bottom,
        this.settings.padding.left,
      ]
    }

    // Industries
    if (d.depth === NodeDepth.Industry) {
      if (LayoutGenerator.isNodeHeaderVisible(d, this.settings)) {
        return [
          this.settings.industry.padding.top + this.settings.industry.header.height,
          this.settings.industry.padding.right,
          this.settings.industry.padding.bottom,
          this.settings.industry.padding.left,
        ]
      }

      return [
        LayoutGenerator.smallIndustryGeoPadding.top,
        LayoutGenerator.smallIndustryGeoPadding.right,
        LayoutGenerator.smallIndustryGeoPadding.bottom,
        LayoutGenerator.smallIndustryGeoPadding.left,
      ]
    }

    // Sectors
    return [
      this.settings.sector.padding.top + this.settings.sector.header.height,
      this.settings.sector.padding.right,
      this.settings.sector.padding.bottom,
      this.settings.sector.padding.left,
    ]
  }

  _getHierarchySort = <
    Datum extends { y0?: number; y1?: number; value?: number; data: { name: string }; depth?: number },
  >(
    a: Datum,
    b: Datum,
    order?: constants.MapOrder
  ): number => {
    // Sort small sectors (works only when layout is recalculated)
    const heightB = b.y1! - b.y0!
    if (b.depth === NodeDepth.Sector && Number.isFinite(heightB) && heightB < this.minSectorHeight) {
      return b.value! - a.value!
    }

    if (order?.hasOwnProperty(a.data.name) && order?.hasOwnProperty(b.data.name)) {
      return order[b.data.name] - order[a.data.name]
    }
    return b.value! - a.value!
  }

  _getHierarchy = (data: MapDataRoot, order?: constants.MapOrder) =>
    d3
      .hierarchy(data as unknown as MapDataNode)
      .sum((d) => d.value)
      .sort((a, b) => this._getHierarchySort(a, b, order))

  _getTreemap = (width: number, height: number) => {
    const paddingFn = this.type === MapTypeId.World ? this._getPaddingGeo : this._getPadding

    return d3
      .treemap<MapDataNode>()
      .size([width, height])
      .round(true)
      .tile(d3.treemapSquarify.ratio(1))
      .paddingTop((d) => paddingFn(d)[0])
      .paddingRight((d) => paddingFn(d)[1])
      .paddingBottom((d) => paddingFn(d)[2])
      .paddingLeft((d) => paddingFn(d)[3])
  }

  _getIndustriesRoots(data: MapDataRoot, industries: string[]) {
    return data.children[0].children.filter(function (d) {
      return industries.some((i) => i === d.name)
    })
  }

  _getSectorsRoots(data: MapDataRoot, sectors: string[]) {
    return data.children.filter(function (d) {
      return sectors.some((s) => s === d.name)
    })
  }

  _generateLayout(data: MapDataRoot, order?: constants.MapOrder, recountSectors = false) {
    const treemapHeight = LayoutGenerator.calculateHeight(this.width, this.type, false)
    const treemap = this._getTreemap(this.width, treemapHeight)
    const hierarchy = this._getHierarchy(data, order)
    const recalculateHeight = this.height !== treemapHeight

    // Calculate the base layout
    let nodes = treemap(hierarchy)

    // Recalculate for custom height
    if (this.height !== treemapHeight) {
      treemap.tile(d3.treemapResquarify).size([this.width, this.height])
    }

    // Run layout again so that possible small sectors are reordered
    // This also applies the recalculateHeight option
    if (recountSectors || recalculateHeight) {
      nodes = treemap(nodes.sort((a, b) => this._getHierarchySort(a, b, order)))
    }

    return nodes
  }

  _generateSP500(data: MapDataRoot) {
    const nodes = this._generateLayout(data, this.isSmall ? constants.ORDER_SP500_SMALL : constants.ORDER_SP500)

    // Switch financial and technology sector
    const financialSector = nodes.find((node) => node.data.name === 'Financial')!
    const technologySector = nodes.find((node) => node.data.name === 'Technology')!
    const financialHeight = financialSector.y1 - financialSector.y0
    const technologyHeight = technologySector.y1 - technologySector.y0

    financialSector.eachAfter((node) => {
      if (node.y1 - node.y0 < 1) return
      node.y0 += technologyHeight
      node.y1 += technologyHeight
    })

    technologySector.eachAfter((node) => {
      if (node.y1 - node.y0 < 1) return
      node.y0 -= financialHeight
      node.y1 -= financialHeight
    })

    if (this.isSmall) {
      // Switch consumer sectors
      const consumerDefensiveSector = nodes.find((node) => node.data.name === 'Consumer Defensive')!
      const consumerCyclicalSector = nodes.find((node) => node.data.name === 'Consumer Cyclical')!
      const consumerDefensiveWidth = consumerDefensiveSector.x1 - consumerDefensiveSector.x0
      const consumerCyclicalWidth = consumerCyclicalSector.x1 - consumerCyclicalSector.x0

      consumerDefensiveSector.eachAfter((node) => {
        if (node.x1 - node.x0 < 1) return
        node.x0 += consumerCyclicalWidth
        node.x1 += consumerCyclicalWidth
      })

      consumerCyclicalSector.eachAfter((node) => {
        if (node.x1 - node.x0 < 1) return
        node.x0 -= consumerDefensiveWidth
        node.x1 -= consumerDefensiveWidth
      })
    }

    return nodes
  }

  _generateFull(data: MapDataRoot) {
    const layout = this._generateLayout(data, constants.ORDER_SEC_ALL)

    // Swap Technology and financial sectors
    const technologySector = layout.find((node) => node.depth === NodeDepth.Sector && node.data.name === 'Technology')!
    const financialSector = layout.find((node) => node.depth === NodeDepth.Sector && node.data.name === 'Financial')!

    const financialHeight = financialSector.y1 - financialSector.y0
    technologySector.each((node) => {
      node.y0 -= financialHeight
      node.y1 -= financialHeight
    })

    const technologyHeight = technologySector.y1 - technologySector.y0
    financialSector.each((node) => {
      node.y0 += technologyHeight
      node.y1 += technologyHeight
    })

    // Swap Consumer Cyclical and Consumer Defensive
    const consumerCyclical = layout.find(
      (node) => node.depth === NodeDepth.Sector && node.data.name === 'Consumer Cyclical'
    )!
    const consumerDefensive = layout.find(
      (node) => node.depth === NodeDepth.Sector && node.data.name === 'Consumer Defensive'
    )!

    const consumerDefensiveWidth = consumerDefensive.x1 - consumerDefensive.x0
    consumerCyclical.each((node) => {
      node.x0 -= consumerDefensiveWidth
      node.x1 -= consumerDefensiveWidth
    })

    const consumerCyclicalWidth = consumerCyclical.x1 - consumerCyclical.x0
    consumerDefensive.each((node) => {
      node.x0 += consumerCyclicalWidth
      node.x1 += consumerCyclicalWidth
    })

    return layout
  }

  _generateScreener(data: MapDataRoot) {
    return this._generateLayout(data, constants.ORDER_SCREENER, true)
  }

  _generateETF(data: MapDataRoot) {
    return this._generateLayout(data, constants.ORDER_ETF)
  }

  _generateWorld = (data: MapDataRoot) => {
    const originalWidth = 1211
    const ratio = this.width / originalWidth
    let nodes: Array<d3.HierarchyRectangularNode<MapDataNode>> = []

    for (let industryIndex = 0; industryIndex < constants.WORLD_INDUSTRIES.length; industryIndex++) {
      const industry = constants.WORLD_INDUSTRIES[industryIndex]
      const industryX = Math.round(industry.x * ratio)
      const industryY = Math.round(industry.y * ratio)
      const industryWidth = Math.round(industry.dx * ratio)
      const industryHeight = Math.round(industry.dy * ratio)

      const countriesRoots = this._getIndustriesRoots(data, industry.countries)
      const root = this._rootsToRoot(countriesRoots)
      const hierarchy = this._getHierarchy(root, constants.ORDER_WORLD)
      const treemap = this._getTreemap(industryWidth, industryHeight)
      const treemapNodes = treemap(hierarchy)

      treemapNodes.each((d) => {
        d.x0 += industryX
        d.x1 += industryX
        d.y0 += industryY
        d.y1 += industryY
      })

      nodes = nodes.concat(treemapNodes.descendants())
    }

    return nodes
  }

  _generateSectorSizeOrdered(data: MapDataRoot) {
    return this._generateLayout(data)
  }
}
