import * as React from 'react'

import { ScreenerQuery, ScreenerView } from '../screener/utils'
import { isMobile } from '../shared/isMobile'
import { isStockFastRefreshAvailable } from '../shared/isStockFastRefreshAvailable'
import { EXPANDED_MAP_BODY_CLASSS } from './constants/constants'
import { LEGEND_HEIGHT, MAP_MIN_HEIGHT, MAP_MIN_WIDTH } from './constants/generator'
import { Settings, SettingsSmall, SettingsSmallGeo } from './constants/settings'
import LayoutGenerator from './layout-generator'
import type Treemap from './treemap'
import {
  MapDataIndustry,
  MapDataNode,
  MapDataRoot,
  MapDataRow,
  MapDataSector,
  MapSubtypeId,
  MapTypeId,
  PerfData,
} from './types'

export function getOffset(e: MouseEvent | React.MouseEvent<HTMLCanvasElement>) {
  const rect = (e.currentTarget as HTMLElement).getBoundingClientRect()

  return {
    offsetX: e.clientX - rect.left,
    offsetY: e.clientY - rect.top,
  }
}

export function getIsSmall() {
  const query = new URLSearchParams(window.location.search)
  return query.get('settings') === 'small'
}

export function getSettingsForMapType(type: MapTypeId, isSmall?: boolean) {
  if (isSmall) {
    if (type === MapTypeId.World) {
      return SettingsSmallGeo
    }
    return SettingsSmall
  }

  return Settings
}

type DataRow = MapDataRow | MapDataRoot
/**
 * We strip parents when serializing data for precomputed JSONs
 * but we need to restore them so that we can get them in treemap
 */
export function restoreDataKeys<T extends DataRow & { children?: MapDataRow[] }>(
  data: T[],
  perfData: PerfData,
  parent?: any
): T[] {
  return data.map((row) => {
    const newRow = {
      ...row,
      parent,
      perf: (perfData.nodes as Record<string, number>)[row.name],
      additional: perfData.additional[row.name],
    }

    newRow.children = row.children ? restoreDataKeys(row.children, perfData, newRow) : undefined

    return newRow
  })
}

export function splitData(mapData: MapDataRoot) {
  let sectors: MapDataSector[] = []
  let industries: MapDataIndustry[] = []
  let nodes: MapDataNode[] = []

  mapData.children?.forEach((sector) => {
    sectors.push(sector)
    sector.children?.forEach((industry) => {
      industries.push(industry)
      industry.children?.forEach((company) => {
        if (!(company.dx < 1 || company.dy < 1)) {
          nodes.push(company)
        }
      })
    })
  })

  return { sectors, industries, nodes }
}

export function getMapData(mapData: MapDataRoot, perfData: PerfData) {
  const [data] = restoreDataKeys([mapData], perfData)

  return splitData(data)
}

export function getIndexMapData() {
  if (FinvizSettings.hasRedesignEnabled) {
    return import('./assets/base_data/hp_redesign.js' /* webpackChunkName: "map_base_index_redesign" */)
  } else {
    return import('./assets/base_data/hp.js' /* webpackChunkName: "map_base_index" */)
  }
}

export function getMapBaseData(mapType: MapTypeId) {
  switch (mapType) {
    case MapTypeId.World:
      return import('./assets/base_data/geo.js' /* webpackChunkName: "map_base_geo" */)
    case MapTypeId.SectorFull:
      return import('./assets/base_data/sec_all.js' /* webpackChunkName: "map_base_sec_all" */)
    case MapTypeId.ETF:
      return import('./assets/base_data/etf.js' /* webpackChunkName: "map_base_etf" */)
    default:
      return import('./assets/base_data/sec.js' /* webpackChunkName: "map_base_sec" */)
  }
}

function getSizeForExpandedMap(element: HTMLElement, wrapper: HTMLElement | null, type: MapTypeId, isSmall: boolean) {
  wrapper?.style.removeProperty('max-width')
  let size = getRatioSizeForWidth(element.clientWidth, type, isSmall)
  const elementHeight = Math.max(window.innerHeight - LEGEND_HEIGHT - 50, MAP_MIN_HEIGHT)

  // We don’t want the map to overflow the height
  if (size.height > elementHeight) {
    const newWidth = LayoutGenerator.calculateWidth(elementHeight, type, isSmall)
    size = {
      width: newWidth,
      height: LayoutGenerator.calculateHeight(newWidth, type, isSmall),
    }
  }

  if (wrapper) {
    wrapper.style.maxWidth = `${size.width}px`
  }

  return size
}

function getRatioSizeForWidth(width: number, type: MapTypeId, isSmall: boolean) {
  const mapWidth = Math.max(width, MAP_MIN_WIDTH)
  return {
    width: mapWidth,
    height: LayoutGenerator.calculateHeight(mapWidth, type, isSmall),
  }
}

function getSizeRatioBasedMap(element: HTMLElement, wrapper: HTMLElement | null, type: MapTypeId, isSmall: boolean) {
  // Remove maxWidth if exiting expanded mode
  if (wrapper?.style.maxWidth) {
    wrapper.style.removeProperty('max-width')
  }

  let size = getRatioSizeForWidth(element.clientWidth, type, isSmall)

  // Set height so that we know if scrollbars are visible
  element.style.height = `${size.height + LEGEND_HEIGHT}px`

  // Add a bit of padding if scrollbar is visible and map touches it
  const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth
  const isMediumScreen =
    window.innerWidth > 1430 &&
    window.innerWidth < 1450 &&
    !document.body.classList.contains('is-screener') &&
    !document.body.classList.contains('is-portfolio')
  if (isMediumScreen && scrollbarWidth > 0) {
    size = getRatioSizeForWidth(element.clientWidth - 10, type, isSmall)

    // This means we would display a horizontal scrollbar, which we don’t want
  } else if (size.width !== element.clientWidth) {
    size = getRatioSizeForWidth(element.clientWidth, type, isSmall)
  }

  element.style.removeProperty('height')

  return size
}

/**
 * Calculate size of the treemap based on w/h ratio and scrollbars visibility
 */
export function getSize(element: HTMLElement | null, type: MapTypeId, isSmall: boolean) {
  const canvasWrapper = document.getElementById('canvas-wrapper')

  if (!element) {
    return { width: 1, height: 1 }
  }

  // Custom width using query param
  const query = new URLSearchParams(window.location.search)
  if (query.has('width')) {
    const width = Number(query.get('width'))
    const parsedRatio = query.has('ratio') ? parseFloat(query.get('ratio')!) : undefined
    const ratio = Number.isFinite(parsedRatio) ? parsedRatio : undefined
    return {
      width,
      height: LayoutGenerator.calculateHeight(width, type, isSmall, ratio),
    }
  }

  // Expanded map
  if (document.body.classList.contains(EXPANDED_MAP_BODY_CLASSS)) {
    return getSizeForExpandedMap(element, canvasWrapper, type, isSmall)
  }

  if (type === MapTypeId.ETFHoldings || type === MapTypeId.ManagersAndFunds) {
    return { height: element.clientHeight, width: element.clientWidth }
  }

  return getSizeRatioBasedMap(element, canvasWrapper, type, isSmall)
}

/**
 * Get treemap zoom levels based on env
 */
export function getDefaultZoomLevels() {
  if (isMobile()) {
    return [1, 1.5]
  }

  if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
    return [1, 1.5, 2.25]
  }

  return [1, 1.5, 2.25, 3]
}

export function parseInitDataAsPerf(data?: MapDataRoot): PerfData | undefined {
  if (!data) return

  const nodes: Record<string, number> = {}
  const additional: Record<string, string> = {}

  for (const sector of data.children) {
    for (const industry of sector.children) {
      for (const node of industry.children) {
        if (node.perf !== undefined) {
          nodes[node.name] = node.perf
        }
        if (node.additional !== undefined) {
          additional[node.name] = node.additional
        }
      }
    }
  }

  return {
    nodes,
    additional,
    subtype: MapSubtypeId.DayPerf,
    version: 1,
    hash: data.hash,
  }
}

const MAX_SPARKLINES = 120
const HOVER_TOP_ROW_HEIGHT = 100
const HOVER_BORDER_WIDTH = 4
const ROW_HEIGHT = 30
const ROW_HEIGHT_SMALL = 24
const ROW_HEIGHT_BREAKPOINT = 15

export function getVisibleTooltipNodes(node?: MapDataNode | null) {
  if (!node) return []

  const industryTickers = node.parent.children
  const isSmall = industryTickers.length > ROW_HEIGHT_BREAKPOINT
  const rowHeight = isSmall ? ROW_HEIGHT_SMALL : ROW_HEIGHT
  const visibleRows = Math.floor((window.innerHeight - HOVER_TOP_ROW_HEIGHT - 2 * HOVER_BORDER_WIDTH) / rowHeight)
  const visibleChildren = industryTickers
    .slice()
    .sort((a, b) => b.dx * b.dy - a.dx * a.dy)
    .slice(0, Math.min(visibleRows, MAX_SPARKLINES))

  return visibleChildren
}

export function onNodeClick({
  treemap,
  node,
  industry,
  sector,
}: {
  treemap: Treemap
  node?: MapDataNode
  industry?: MapDataIndustry
  sector?: MapDataSector
}) {
  if (node) {
    window.open(`/quote.ashx?t=${node.name}`)
    return
  }

  const query = new URLSearchParams()
  query.set(ScreenerQuery.View, ScreenerView.Overview.toString())

  // Use ticker filter for ETFs
  if (treemap.type === MapTypeId.ETF) {
    let tickers: string[] = []
    if (industry) {
      tickers = industry.children.map((node) => node.name)
    } else if (sector) {
      tickers = sector.children.flatMap((industry) => industry.children.map((node) => node.name))
    }

    if (tickers.length) {
      query.set(ScreenerQuery.Tickers, tickers.join(','))
      window.open(`/screener.ashx?${query}`)
    }

    return
  }

  if (industry) {
    const id = industry.name.replace(/[^a-zA-Z]/g, '').toLowerCase()
    const filter = treemap.type === MapTypeId.World ? 'geo' : 'ind'

    query.set(ScreenerQuery.Filters, `${filter}_${id}`)
    window.open(`/screener.ashx?${query}`)
  } else if (sector) {
    const id = sector.name.replace(/[^a-zA-Z]/g, '').toLowerCase()

    query.set(ScreenerQuery.Filters, `sec_${id}`)
    window.open(`/screener.ashx?${query}`)
  }
}

enum MapsPollingIntervalInMs {
  Free = 30000,
  Elite = 3000,
  Reduced = 60000,
}

export function getMapsRefreshInterval({
  base = MapsPollingIntervalInMs.Elite,
  reduced = MapsPollingIntervalInMs.Reduced,
  free = MapsPollingIntervalInMs.Free,
}: { base?: number; reduced?: number; free?: number } = {}) {
  const isPremium = FinvizSettings.hasUserPremium
  let customRefreshInterval = base
  if (!isPremium) {
    customRefreshInterval = free
  }
  if (!isStockFastRefreshAvailable()) {
    customRefreshInterval = reduced
  }

  return customRefreshInterval
}
