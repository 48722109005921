export type ObjectHash<T = any, K extends string | number = string> = Record<K, T>

export const decodeQueryString = function <T extends ObjectHash<string, string> = ObjectHash<string, string>>(
  search = window.location.search
): T {
  const vars = search ? search.substring(1).split('&') : []
  const obj: ObjectHash = {}
  vars.forEach((pair) => {
    const delimiterPos = pair.indexOf('='),
      key = decodeURIComponent(pair.substring(0, delimiterPos)),
      value = decodeURIComponent(pair.substring(delimiterPos + 1))
    obj[key] = value
  })
  return obj as T
}

export const encodeQueryString = function (values: ObjectHash, whitelistedValues: string[] = []) {
  return Object.keys(values).reduce((prev, key) => {
    const value = values[key]
    if (!['', null, undefined].includes(value) || whitelistedValues.includes(value)) {
      return prev + (prev.length > 0 ? '&' : '') + encodeURIComponent(key) + '=' + encodeURIComponent(value)
    }
    return prev
  }, '')
}
